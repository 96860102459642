.react-datepicker-wrapper {
  display: block;
}
.react-datepicker__input-container {
  display: block;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding-right: 0px;
  padding-left: 0px;
}
