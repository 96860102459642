.textColorGray {
  color: gray !important;
}

.textDefaultColor {
  color: rgba(0, 0, 0, 0.87);
}

.width25Percent {
  width: 25% !important;
}

.dropdown .icon {
  padding-left: 0;
}

.rowEmailStyle {
  max-width: 300px;
  overflow-wrap: break-word;
}