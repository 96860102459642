.preferencesHeader {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
}

.mtb-2em {
  margin: 2em 0px 2em 0 !important;
}

.preferencesInfo {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: right;
  grid-template-rows: auto auto;
  gap: 20px;
  margin-top: 20px;
  line-height: 3;
}

/* Rule Set */
.ruleSetHeaderAction {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;

  position: absolute;
  right: 0px;
  transform: translateY(-55px);
}

.ruleSetContainer {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
}

.ruleSetContainerHeader {
  margin-bottom: 25px;
}

.border {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
}

.ml-5px {
  margin-left: 5px !important;
}

.mr-5px {
  margin-right: 5px !important;
}

.tabInnerRuleSet > div.attached.tabular.menu > a {
  background: #f3efef !important;
}

.ruleSetItemElem {
  margin-left: 5px;
}

.defaultPreferencesRuleType {
  text-align: right;
}

.mb30px {
  margin-bottom: 30px;
}

.ui.icon.button > .icon {
  opacity: 1;
}

.ruleSetForm {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 400px;
  padding: 8px;
}

.ruleSetForm::-webkit-scrollbar {
  width: 6px;
}
