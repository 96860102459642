.ui.modal > .content > .description {
  flex: 1 0 !important;
}

.vst-grid {
  padding-top: 10px !important;
}

.vst-grid .vst-grid .ui.grid > [class*='right aligned'].row > .column {
  text-align: left !important;
}

.vst-grid .ui.basic.label {
  width: 90% !important;
  text-align: left !important;
  min-height: 25px !important;
}

.accordion-div .ui[class*='right aligned'].grid > .row > .column {
  text-align: left !important;
}

.vst-grid strong {
  padding-right: 20% !important;
}

.error-text {
  color: #d8000c;
}
.warn-text {
  color: #9f6000;
}
.table-field {
  width: 200px;
}
.min-height .menu {
  height: 115px;
}
.ui.error input[type='text'] {
  color: #d8000c;
  border: 1px solid #ff9898;
  background-color: #ffeded;
}

.ui.error input[type='text']:focus {
  color: #d8000c;
  border: 1px solid #ff9898;
  background-color: #ffeded;
}

.right-icon {
  float: right !important;
}

.accordion-title {
  background-color: #666666 !important;
  color: white !important;
}

.accordion-content {
  background-color: #b0b0b0 !important;
}

.accordion-div {
  /*padding-bottom: 10px;*/
}

.vst-close-button,
.vst-open-button {
  color: white;
  float: right;
  background-color: #cc6600 !important;
}

.green-font {
  color: #006633 !important;
}

.black-font {
  color: black !important;
}

.red-font {
  color: #ff0000 !important;
}

.vin-header {
  float: left !important;
  color: #000066 !important;
}

.ui.grid > .column,
.ui.grid > .row > .column {
  width: 6.25%;
}

.payment-status {
  float: left !important;
}

.email-frequency-selector {
  width: 85% !important;
  margin-right: 5% !important;
}

.product-type-selector {
  width: 100% !important;
}

.accept-reject-button {
  margin-top: 25px;
}

.react-drop-zone {
  width: 100% !important;
  height: 50px !important;
  position: relative;
  border-width: 2px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
  text-align: center;
  line-height: 45px;
}

.close-icon {
  float: right;
  font-size: 2em !important;
}

.react-drop-zone-small {
  width: 50% !important;
  position: relative;
  display: inline-block;
  margin-right: 5%;
  margin-bottom: 50px;
}

.enrollment-link {
  border-left: 0px solid !important;
}

.dealer-details {
  margin-top: 25px;
}

.disabledinput {
  opacity: 1 !important;
}

.disabledinput .ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  opacity: 1 !important;
}

.confirm-form .ui.button {
  display: none;
}

.confirm-form .ui.primary.button {
  display: inline-block;
}

.footer-aco {
  margin: 20px 0;
  font-size: 80%;
}

.header-content-aco {
  display: block;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 700;
  margin: -0.14285714em 0 0 0 !important;
  font-size: 1.14285714em !important;
  margin-top: 5px !important;
  opacity: 1 !important;
  color: black !important;
}

.header-content-aco .header {
  font-size: 1em !important;
}

.dealer-enrollment > .dealer_quote {
  padding-left: 15px;
  padding-right: 50px;
}

.external_auction_transactions_table table {
  display: block;
  max-height: 600px;
  overflow-y: scroll;
}

.external_auction_transactions_table thead th {
  position: sticky;
  top: 0px;
}
.ui.checkbox label {
  padding-left: 1.4em !important;
}

.item-checkbox-right {
  left: 0.5em !important;
  top: 0.3em !important;
}

.offer_since_label {
  font-size: 1em !important;
  font-weight: 500 !important;
}

.margin_spacing {
  margin-left: 10%;
}

.certificate {
  line-height: 1.2em;
  padding: 1.5em 0 0 0;
  font-size: 16px;
}

.certificate h1,
h2,
.sm,
footer {
  color: #99471b;
}

.certificate h1 {
  font-size: 2em;
}

.certificate h2 {
  font-weight: normal;
  font-size: 1.3em;
}

.certificate header {
  text-align: center;
}
.certificate section.p.strong {
  text-transform: uppercase;
  margin-right: 15px;
}

.certificate p {
  margin: 1em;
}

.certificate img {
  max-width: 30% !important;
}

.certificate section {
  border-top: 1px solid #7187ae;
  padding: 10px 0;
}

.certificate svg {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  text-align: center;
  padding: 10px 0;
  height: 86px;
  overflow: hidden;
  margin-bottom: 20px;
}

.certificate table {
  width: 100%;
  padding-bottom: 10px;
}
.certificate table th {
  text-align: left;
  text-transform: uppercase;
  padding-right: 15px;
  width: 36%;
}

.certificate table.bold {
  background: #f6f6f6;
  border: 2px solid #000;
}

.certificate table.bold th:first-child {
  padding-left: 10px !important;
}
.certificate table.bold td:last-child {
  padding-right: 10px;
}
.certificate table.bold tr:first-child th td {
  padding-top: 10px;
}
.certificate table.bold tr:last-child th td {
  padding-bottom: 10px;
}

.certificate footer {
  text-align: center;
  font-weight: bold;
}
.certificate footer h2 {
  font-weight: bold;
  margin: 0;
  text-decoration: underline;
  font-size: 0.9em;
}
.certificate footer p {
  margin: 0;
}

.btn {
  background-color: #f5cc4d;
  border: 1px solid #cacaca;
  border-radius: 5px;
  color: #4a4a4a;
  display: inline-block;
  font-size: 1em;
  font-weight: bold;
  line-height: 1em;
  font-weight: normal;
  padding: 1em 1.5em;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  margin-left: 140px;
}

@media print {
  .stackable,
  .btn,
  #non-printable {
    display: none !important;
  }
  #printable {
    display: block;
  }
}

.input-disabled > input {
  background: lightgray !important;
  color: black !important;
  opacity: 1 !important;
}

.input-disabled {
  background: lightgray !important;
  color: black !important;
  opacity: 1 !important;
}

.input-disabled {
  background: lightgray !important;
  color: black !important;
  opacity: 1 !important;
  color: black !important;
  opacity: 1 !important;
}

.selection-override > .menu > .message {
  text-align: center;
}
.menu > .disabled.item {
  opacity: 1 !important;
}
.selection-override.input-disabled {
  background: lightgray !important;
}
.short-scrolling-dropdown .menu {
  max-height: 185px !important;
  overflow-y: auto;
}

.pre-wrap-dropdown .menu .item {
  white-space: pre-wrap !important;
}

.pre-wrap-table td:first-child {
  white-space: pre-wrap !important;
}

.space-pre-wrap {
  white-space: pre-wrap !important;
}

.icon-35 {
  width: 35px;
  height: 35px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-35 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.d-center .direction-col {
  flex-direction: column;
}

.dropzone-container {
  width: 100%;
  height: 80px;
  position: relative;
  border-width: 2px;
  border-color: #4b4b4b;
  border-style: dotted;
  border-radius: 5px;
  text-align: center;
  margin: 10px 0px;
  line-height: 14px;
  font-size: 14px;
}

.table-container {
  height: 470px;
  position: relative;
  scroll-behavior: smooth;
}

.table-container ::-webkit-scrollbar {
  width: 4px;
}

.table-container table {
  width: 100%;
  display: block;
  overflow: auto;
  max-height: 470px;
}

.table-container table thead {
  position: sticky;
  top: 0;
  background: #ffffff;
}

.table-container table thead,
.table-container table tbody {
  width: 100%;
  display: table;
}

.table-container table th,
.table-container table td {
  word-break: break-all;
  white-space: pre-line;
  vertical-align: baseline;
}

.table-container table th:nth-child(2n + 1),
.table-container table td:nth-child(2n + 1) {
  width: 10%;
}

.table-container table th:nth-child(2n),
.table-container table td:nth-child(2n) {
  width: 35%;
}

.error-container {
  height: fit-content;
  max-height: 430px;
  overflow: auto;
}
