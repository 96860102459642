.error_page_outer {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

.error_page_outer .error_container {
  margin: auto;
  max-width: 540px;
  min-width: 200px;
  margin-top: 1%;
  background: #fff;
  padding: 0 10px 10px 0;
}

.error_page_outer .box hr {
  display: block;
  border: none;
  border-bottom: 1px dashed #ccc;
}

.error_page_outer .box h2 {
  text-align: center;
}

.error_page_outer .box {
  border-radius: 3px;
  color: black;
  padding: 20px;
}

.outerContainer {
  background-color: white;
  text-align: center;
  height: 100vh;
  position: absolute;
  width: 100vw;
  margin: 0 !important;
  overflow: hidden;
}

.maintenance-outer {
  margin-top: 10%;
  font-weight: 800;
}

.maintenance-outer .header {
  font-weight: 800;
}

.maintenance-outer .message {
  margin-top: 40px;
}

.maintenance-outer .maintenance-btn {
  margin-top: 40px;
  margin-left: unset !important;
}

.loader {
  position: relative;
  border-radius: 50%;
  height: 100px;
  margin: auto;
  -webkit-transform: unset !important;
  -o-transform: unset !important;
  transform: unset !important;
  width: 100px;
  z-index: 1;
}

.loader::before {
  content: unset !important;
}

.loader::after {
  content: '';
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  background-color: white;
  background-image: url(split-shield.svg);
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center 60%;
  border-radius: 50%;
  height: 100px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100px;
  z-index: 1;
}

center {
  display: none;
}

.btn {
  margin-top: 30px;
}

.maintenance-outer h2 {
  font-size: 3.714rem;
}
